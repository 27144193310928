import {Link} from 'gatsby'
import Button from '../../uikit/Button'
import Flex from '../../uikit/Flex'
import Text from '../../uikit/Text'
import Signature from '../../assets/icons/signature.svg'


const ThankYouContact = () => {
  return (
    <Flex column crossAxis="center" mainAxis="justify" height="fill">
      <Flex column crossAxis="center">
        <Text component="h1" textStyle="h1" align="center">
          <strong>Ďakujem</strong>
          <br />
          za Vašu správu!
        </Text>
        <p>
          V priebehu najbližších dní vás budem kontaktovať.
        </p>
        <Text component="p" align="center">S pozdravom</Text>
        <Signature />
      </Flex>
      <Flex>
        <Button component={Link} isLink to="/" onBottom>
          späť na hlavnú stránku
        </Button>
      </Flex>
    </Flex>
  )
}

export default ThankYouContact
