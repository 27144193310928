import {useState} from 'react'
import mobileBackground from '../../content/media/kot_kontakt-thankyou-m.jpg'
import desktopBackground from '../../content/media/kot_kontakt-thankyou-d.jpg'
import Appshell from '../components/Appshell'
import ThankYouContact from '../components/screens/ThankYouContact'
import useForm from '../hooks/useForm'
import Background from '../uikit/Background'
import Button from '../uikit/Button'
import Flex from '../uikit/Flex'
import TextArea from '../uikit/form/TextArea'
import TextInput from '../uikit/form/TextInput'
import Text from '../uikit/Text'
import encode from '../utils/encode'


const Contact = () => {
  const {values, handleChange} = useForm()
  const [isSubmitted, setSubmitted] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()

    fetch('/', {
      method: 'POST',
      body: encode({
        'form-name': 'contact',
        ...values,
      }),
    })
      .then(() => {
        setSubmitted(true)
      })
      .catch((error) => console.error(error))
  }

  if (isSubmitted) {
    return (
      <Appshell>
        <Background mobileBackground={mobileBackground} desktopBackground={desktopBackground}>
          <ThankYouContact />
        </Background>
      </Appshell>
    )
  }

  return (
    <Appshell>
      <Background mobileBackground={mobileBackground} desktopBackground={desktopBackground}>
        <Flex column>
          <Text component="h1" textStyle="h1" align="center">
            Napíšte mi!
          </Text>
          <p>
            V prípade akýchkoľvek otázok ma neváhajte kontaktovať na:
          </p>
          <a href="mailto:info@knihyodtomasa.sk">info@knihyodtomasa.sk</a>
          <p>Využiť môžete aj kontaktný formulár nižšie:</p>
          <form
              name="contact"
              method="post"
              action="/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
          >
            <input type="hidden" name="form-name" value="contact" />
            <Flex column>
              <TextInput
                  type="email"
                  name="email"
                  placeholder="Váš e-mail"
                  value={values?.email}
                  onChange={handleChange}
                  required
              />
              <TextArea
                  name="message"
                  value={values?.message}
                  onChange={handleChange}
                  placeholder="Správa"
                  type="textarea"
                  rows="10"
                  required
              />
              <Flex mainAxis="center">
                <Button type="submit">
                  ODOSLAŤ
                </Button>
              </Flex>
            </Flex>
          </form>
        </Flex>
      </Background>
    </Appshell>
  )
}

export default Contact
